import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

export const title = "Error handling";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Error handling

---

# Handling errors

- Rust does not have exceptions
- You can:
  - return an error (`Option`, `Result`)
  - panic

---

# Panicking

- Tears down the current thread
  - If it's the main thread, program exits
- Safe to do, in Rust terms

---

# Direct panics

import kindsOfPanics from "rust:error-handling/kinds-of-panics";

<Transclude src={kindsOfPanics} focusOn="2+1" />

<Transclude src={kindsOfPanics} focusOn="3+1" />

<Transclude src={kindsOfPanics} focusOn="4+2" />

<Transclude src={kindsOfPanics} focusOn="6+2" />

<Notes />

- All do the same, different meaning for the programmer

---

# Indirect panics

- `Option::unwrap()` / `Result::unwrap()`
- `Option::expect("reason")` / `Result::expect("reason")`
- Indexing out of bounds (`foo[1000]`)

<Notes />

- These exist for convenience
- Ways to avoid the panic exist

---

# Recovering from a panic

- `std::panic::catch_unwind`
- Really only useful for bigger frameworks
  - Web server

---

# When is a panic not a panic?

- Can add `panic = "abort"` to your Cargo.toml
  - Any panic will abort the process
- Panicking during another panic also causes an abort
- Call `std::process::abort`

---

# Panic vs returning an error

- When in doubt, prefer returning an `Option` or `Result`
- When to panic:
  - Great for prototyping and "learning Rust" workshops
  - OK for tests
  - OK for an executable
  - Not good for a library
    - Unless there's an error from the library writer
    - Or a convenience function, equivalent to `[]`

---

# The type inside `Result::Err`

- Well-designed APIs usually use an error type that implements `std::error::Error`

---

# `std::error::Error`

```rust
pub trait Error: Debug + Display {
    fn source(&self) -> Option<&(dyn Error + 'static)> { /* ... */ }
}
```

<Notes />

- Can be printed
- Can get the underlying cause
- Other methods, but they are deprecated.

---

# Unifying errors

import unifyingErrors from "rust:error-handling/unifying-errors";

<Transclude src={unifyingErrors} focusOn="1+11" />

<Notes />

- We want to be able to report either type of error
- But can only use one return type

---

# The `std::error::Error` trait object

import unifyingErrorsRev1 from "rust:error-handling/unifying-errors?rev=1";

<Transclude src={unifyingErrorsRev1} focusOn="1+11" emphasize="1[34+26]" />

<Notes />

- Any type that implements `Error` can be put into this trait object
- Downside is that you lose ability to easily access any details
- There's also a thread-safe variant of this: `Box<dyn std::error::Error + Send + Sync + 'static>`

---

# Exercise: Unify different error types

- Write a function that accepts two strings
- Parse one string as a `bool`, the other as an `i32`
- Use `?` to return in case of error
- If the boolean is true, multiply the number by 2, otherwise by 3
- Return a unified `Result` to `main` and print out the success or failure

---

# One answer

<Continue />

import exerciseUnifyingErrors from "rust:error-handling/exercise-unifying-errors";

<Transclude src={exerciseUnifyingErrors} />

---

# Returning errors from `main` and tests

import returnFromMainAndTest from "rust:error-handling/return-from-main-and-test";

<Transclude src={returnFromMainAndTest} focusOn="1+3" />

<Transclude src={returnFromMainAndTest} focusOn="5+4" />

<Notes />

- These use `Debug` formatting, which means they aren't great for end users

---

# Easy custom error messages with strings

import stringErrors from "rust:error-handling/string-errors";

<Transclude src={stringErrors} focusOn="1+15" />

---

# Easy custom error messages with strings

<Transclude src={stringErrors} focusOn="1+15" emphasize="3" />

---

# Easy custom error messages with strings

<Transclude src={stringErrors} focusOn="1+15" emphasize="7,8,9" />

---

# Repetitive return types

import resultTypeAlias from "rust:error-handling/result-type-alias";

<Transclude src={resultTypeAlias} focusOn="1+11" />

<Notes />

- Takes up visual space, is repetitive, and hard to change at once

---

# `Result` type aliases

import resultTypeAliasRev1 from "rust:error-handling/result-type-alias?rev=1";

<Transclude src={resultTypeAliasRev1} focusOn="1+15" />

<Notes />

- Common in libraries where many functions return the same error
- May be confusing when seen in the documentation

---

# `Result` type aliases

<Transclude src={resultTypeAliasRev1} focusOn="1+15" emphasize="13,14,15" />

<Notes />

- Common in libraries where many functions return the same error
- May be confusing when seen in the documentation

---

# `Result` type aliases

<Transclude
  src={resultTypeAliasRev1}
  focusOn="1+15"
  emphasize="1[17+12],5[22+12],9[18+12]"
/>

<Notes />

- Common in libraries where many functions return the same error
- May be confusing when seen in the documentation

---

# Custom error types

Production code is likely to have custom error types

import customError from "rust:error-handling/custom-error";

<Transclude src={customError} focusOn="1+5" />

<Notes />

- A part of your public API
- Can have methods
  - further details
  - recover original values

---

# Custom error types

Common implementation is an enum, one variant per possible error

import customErrorRev1 from "rust:error-handling/custom-error?rev=1";

<Transclude src={customErrorRev1} focusOn="1+8" />

---

# Implementing std::error:Error

import customErrorRev2 from "rust:error-handling/custom-error?rev=2";

<Transclude src={customErrorRev2} focusOn="1+12" />

---

# Implementing std::error:Error

<Transclude src={customErrorRev2} focusOn="1+12" emphasize="3" />

---

# Implementing std::error:Error

<Transclude src={customErrorRev2} focusOn="1+12" emphasize="6,7,8,9,10,11" />

---

# Implementing std::error:Error

<Transclude src={customErrorRev2} focusOn="1+12" emphasize="12[0+46]" />

---

# Crates help with this

- SNAFU
- anyhow
- thiserror
- quick-error
- failure
- error-chain

---

# SNAFU

import usingSnafu from "rust:error-handling/using-snafu";

<Transclude src={usingSnafu} />

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
